<template>
    <section class="bg-white border-b">
        <div class="container mx-auto my-8 max-w-screen-xl">
            <div class="flex flex-wrap">
                <div class="w-full h-full sm:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
                    <img src="@/assets/drew.png"  />
                </div>
                <div class="w-full sm:w-1/2 p-6 text-center flex justify-center items-center align-center">
                    <div class="align-center d-flex align-self-center justify-center">
                        <h3 class="text-2xl text-gray-800 font-bold leading-none my-3">
                            Celebrate the Life of Drew Lewis
                        </h3>
                        <p class="font-medium text-xl my-6">
                            Monday August 30th, 2021<br>
                            11AM - 12:30PM<br>
                            9184 Greenwood Rd. Terrel, NC 28682<br>
                        </p>
                        <div class="mw-160 h-200 mh-200 my-8">
                            <a href="https://us02web.zoom.us/j/7047527760" class="mx-auto mb-8 lg:mx-0 hover:underline gradient text-white font-bold p-4 bg-blue-700 rounded-full shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                Via Zoom
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "DefaultHeader"
}
</script>

<style scoped>

</style>
