<template>
    <section>
        <div class="container flex flex-wrap max-w-screen-xl mx-auto py-16">
            <div class="w-full sm:w-1/2 flex">
                <div class="text-left">
                    <h2 class="font-bold text-xl mb-4">
                        Event Details
                    </h2>
                    <p>
                        Casual clothing is recommended and preferred. Bring a chair if possible.
                        Bring a rock from home or any location that you like, enough to write at least two words on.
                    </p>
                    <ul>
                        <li>
                            Bring a Chair
                        </li>
                        <li>
                            Bring a Rock
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-full sm:w-1/2 flex align-center justify-center">
                <google-map />
            </div>
        </div>
    </section>
</template>

<script>
import GoogleMap from "./GoogleMap";
export default {
    name: "LocationDetails",
    components: {GoogleMap}
}
</script>

<style scoped>

</style>
