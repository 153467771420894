<template>
    <section class="bg-white border-b py-12">
        <div class="container mx-auto flex flex-wrap text-center py-6 max-w-screen-xl">
            <div class="w-full flex flex-col flex-grow flex-shrink">
                <p class="text-center text-xl px-4">
                    Join us Monday to celebrate the life of Drew Lewis. Come with an open heart of love, encouragement, healing and renewal.
                    We will be providing lunch and drinks following Drew’s celebration of life. We look forward to Celebrating Drew’s life with you as we move through this season of mourning and heavy hearts.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OverviewSection",
    components: {}
}
</script>

<style scoped>

</style>
