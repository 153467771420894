<template>
    <div class="text-center py-8">
        <div class="text-gray-400">
                Celebrate the life of Drew Lewis
        </div>
    </div>
</template>

<script>
export default {
    name: "DefaultFooter"
}
</script>

<style scoped>

</style>
