<template>
    <section class="border-b py-16 bg-gray-100">
        <div class="container mx-auto flex flex-wrap">
            <h1 class="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">
                Connor Lewis Trust
            </h1>
            <p class="w-full mx-auto mb-4">
                In lieu of flowers or any other condolences, the family is requesting donations be made in Drew’s honor to the Trust of Connor Andrew Lewis.
            </p>
            <div class="w-full md:w-1/3 flex flex-col flex-grow flex-shrink content-between justify-between p-4 mh-300">
                <div class="w-full flex flex-col flex-grow flex-shrink content-center text-center justify-between bg-white flex-grow-1 shadow-lg">
                    <div class="w-full font-bold text-medium text-gray-600 p-3">
                        GoFundMe
                    </div>
                    <div class="mw-160 h-200 mh-200 mb-6">
                        <a href="https://gofund.me/9fe0b597" class="mx-auto mb-8 lg:mx-0 hover:underline gradient blue-text font-bold p-4 bg-blue rounded-full shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                            Visit Page
                        </a>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3 flex flex-col flex-grow flex-shrink content-between p-4">
                <div class="flex flex-col flex-grow flex-shrink bg-white content-between shadow-lg ">
                    <div class="w-full font-bold text-medium text-gray-600 py-3">
                        Check
                    </div>
                    <div class="mt-auto bg-white overflow-hidden">
                            <p class="text-gray-800 text-base px-3 mb-4">
                                Payable to: The Trust of Connor Andrew Lewis<br>
                                Lori Campe 9184 Greenwood Road Terell, NC 28682
                            </p>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-1/3 p-4 flex flex-col flex-grow flex-shrink">
                <div class="flex flex-col flex-grow flex-shrink bg-white content-between shadow-lg ">
                    <div class="flex-1 bg-white overflow-hidden">
                        <div class="w-full font-bold text-medium text-gray-600 py-3">
                            Venmo
                        </div>
                    </div>
                    <div class="flex-none bg-white overflow-hidden px-6">
                        <div class="flex items-center justify-center">
                            <p class="text-gray-800 text-base p-3">
                                   <span class="text-xl font-medium">
                                       @Lori-Campe
                                   </span>
                                <br>
                                <span class="text-gray font-xs">
                                    (please include your name and Connor Lewis Trust)
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
name: "TrustOptions"
}
</script>

<style scoped>

</style>
