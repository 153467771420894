<template>
    <section>
        <div>
            <overview-section />
            <trust-options />
        </div>
    </section>
</template>

<script>
import OverviewSection from "../components/OverviewSection";
import TrustOptions from "../components/TrustOptions";
export default {
    name: "RemoteInvite",
    components: {TrustOptions, OverviewSection}
}
</script>

<style scoped>

</style>
