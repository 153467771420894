<template>
    <div>
        <overview-section />
        <location-details />
        <trust-options />
    </div>
</template>

<script>
import OverviewSection from "../components/OverviewSection";
import LocationDetails from "../components/LocationDetails";
import TrustOptions from "../components/TrustOptions";
export default {
    name: "LocationInvitePage",
    components: {TrustOptions, LocationDetails, OverviewSection}
}
</script>

<style scoped>

</style>
