<template>
    <gmap-map
        :center="{lat:35.5974761, lng:-80.9453174}"
        :zoom="8"
        map-type-id="roadmap"
        style="width: 500px; height: 300px"
        :options="mapOptions"
    >
        <gmap-marker
            :position="google && new google.maps.LatLng(35.5974761, -80.9453174)"
        />
    </gmap-map>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
export default {
    name: "GoogleMap",
    data: () => ({
        marker: {
            position: {
                lat: 35.597097,
                long: -80.9453174,
            }
        },
        mapOptions: {
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
        }
    }),
    computed: {
        google: gmapApi
    }
}
</script>

<style scoped>

</style>
