import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

import * as VueGoogleMaps from 'vue2-google-maps'
import router from './router'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBsQHX9051_whJ03DpcDGUn0KwQdDsCTRY',
    libraries: 'places',
  },
})



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
