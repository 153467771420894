<template>
  <div id="app">
    <div class="mx-auto">
        <default-header />
        <router-view />
        <default-footer />
    </div>
  </div>
</template>

<script>
import DefaultHeader from "./components/DefaultHeader";
import DefaultFooter from "./components/DefaultFooter";
export default {
  name: 'App',
    components: { DefaultFooter, DefaultHeader },
    data: () => ({

    })
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
